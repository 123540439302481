import React, {useContext, useEffect, useRef, useState} from 'react';
import MovePage from "./MovePage";
import FlashCard from "./FlashCard";

const Kanji = () => {
    return (
        <div className="kanjis">

        </div>
    )
}

export default Kanji;