import React, {forwardRef} from 'react';
import {useNavigate} from "react-router-dom";

const MovePage = forwardRef(({path, text, classNm}, ref) => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    }

    return (
        <button ref={ref} className={classNm} onClick={() => handleNavigation(path)}>{text}</button>
    )
});

export default MovePage;