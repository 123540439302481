import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import MovePage from "./MovePage";
import FlashCard from "./FlashCard";
import Cookies from "js-cookie";
import {useSelector} from "react-redux";

const Word = () => {
    const [words, setWords] = useState([]);
    const [changedWords, setChangedWords] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [displayIndex, setDisplayIndex] = useState(0);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const incompleteWords = words.filter(word => word.state == 0); // 학습되지 않은 단어
    const moveRef = useRef(null);
    const cardRef = useRef(null);
    const [isFlipped, setIsFlipped] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isFlashcardVisible, setIsFlashcardVisible] = useState(true);
    const csrfToken = Cookies.get('XSRF-TOKEN');
    const [isAutoFlip, setIsAutoFlip] = useState(false);
    const [flipTimer, setFlipTimer] = useState(null);
    const [nextWordTimer, setNextWordTimer] = useState(null);
    const [isSentence, setIsSentence] = useState(false);
    const [audioEvent, setAudioEvent] = useState([]);
    const [silentAudioEvent, setSilentAudioEvent] = useState([]);
    const isAudioAllowed = useSelector(state => state.isAudioAllowed);
    const [isMobile, setIsMobile] = useState(false);
    const [isPronunciationHidden, setIsPronunciationHidden] = useState(false);
    const [dailyStudy] = useState(localStorage.getItem('dailyStudy') || 0);
    const [page] = useState(0);
    const [size] = useState(dailyStudy > 0 ? dailyStudy : localStorage.getItem('pageSize') || 300);
    const navigate = useNavigate();
    const [wordsLeftCnt, setWordsLeftCnt] = useState([]);
    const [isSilentAudioPlaying, setIsSilentAudioPlaying] = useState(false);
    const currentLevel = useSelector(state => state.currentLevel);
    const isRandom = useSelector(state => state.isRandom);

    useEffect(() => {
        // User-Agent를 사용하여 모바일 장치를 감지
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            setIsMobile(true);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    useEffect(() => {
        if(isDataFetched && incompleteWords.length === 0){
            alert('모든 단어를 외웠습니다!');
            if(confirm('저장하시겠습니까?')){
                handleSave();
            }

            navigate('/');
        }
    }, [incompleteWords]);

    useEffect(() => {
        if(isDataFetched && dailyStudy > 0){
            setWordsLeftCnt(incompleteWords.length);
        }
    }, [isDataFetched, incompleteWords.length]);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // 배열 요소 교환
        }
        return array;
    };

    const fetchWordsFromServer = async () => {
        try {
            const token = localStorage.getItem('token');
            let method = dailyStudy <= 0 ? `findWordsByMemberIdAndState` : `findWordsByDailyStudy`;
            const requestBody = {
                level: currentLevel
            };

            const response = await fetch(`/api/word/${method}?page=${page}&size=${size}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`서버 에러: ${response.status}`);
            }

            const result = await response.json();
            let outputWords;
            if(isRandom){
                outputWords = shuffleArray(result.content);
            }else{
                outputWords = result.content.sort((a, b) => a.id - b.id);
            }

            setWords(outputWords);
            setIsDataFetched(true);
        } catch (error) {
            console.error('데이터 가져오기 실패:', error);
        }
    }

    useEffect(() => {
        fetchWordsFromServer();
    }, []);

    const checkLogin = async () => {
        const token = localStorage.getItem('token');
        if (token == null) {
            setIsLogin(false);
        } else{
            setIsLogin(true);
        }
    }

    useEffect(() => {
        checkLogin();
    }, []);

    const handleKnow = () => {
        if(isFlipped){
            setIsFlipped(false);
        }

        const currentWordId = incompleteWords[currentIndex].id;
        const updatedWords = words.map(word =>
            word.id === currentWordId ? { ...word, state: 1 } : word
        );

        if(currentIndex >= incompleteWords.length -1){
            setCurrentIndex((prevIndex => prevIndex - 1));
        }
        setWords(updatedWords);
        handleWordChange(updatedWords, currentWordId);
        setDisplayIndex((prevIndex) => prevIndex + 1);
    };

    const handleNextWord = () => {
        setCurrentIndex((prevIndex) => {
            const nextIndex = prevIndex + 1;
            if (nextIndex >= incompleteWords.length) {
                // 마지막 단어 이후 처음으로 돌아감
                return 0;
            }
            return nextIndex;
        });
        setDisplayIndex((prevDisplayIndex) => {
            const nextDisplayIndex = prevDisplayIndex + 1;
            if (nextDisplayIndex >= incompleteWords.length) {
                // 마지막 단어 이후 처음으로 돌아감
                return 0;
            }
            return nextDisplayIndex;
        });
    };

    const handleDontKnow = () => {
        if(isFlipped){
            setIsFlipped(false);
        }

        const currentWordId = incompleteWords[currentIndex].id;
        const updatedWords = words.map(word =>
            word.id === currentWordId ? { ...word, incorrect_count: word.incorrect_count + 1 } : word
        );

        setWords(updatedWords);
        handleWordChange(updatedWords, currentWordId);

        setCurrentIndex((prevIndex) => (prevIndex + 1) % incompleteWords.length);
        setDisplayIndex((prevIndex) => prevIndex + 1);
    };

    const handleSentence = () => {
        if(!isSentence){
            setIsSentence(true);
        }else{
            setIsSentence(false);
        }
    }

    const handleAutoPlay = () => {
        handleAutoFlip();
    }

    // 단어 변경사항 추적
    const handleWordChange = (updatedWords, currentWordId) => {
        const alreadyChanged = changedWords.find((word) => word.id === currentWordId);
        const updatedWord = updatedWords.find((word) => word.id === currentWordId);

        if (updatedWord) {
            if (!alreadyChanged) {
                // 변경된 단어가 없으면 추가
                setChangedWords(prev => [...prev, { ...updatedWord }]);
            } else {
                // 이미 변경된 항목이 있으면 업데이트
                setChangedWords(prev =>
                    prev.map(word => word.id === currentWordId ? { ...updatedWord } : word)
                );
            }
        }
    };

    const handleFlip = () => {
        setIsFlipped((prevState) => {
            return !prevState;
        });
    };

    const handleIndexClickEvent = (e) => {
        e.stopPropagation();
        const input = prompt('이동할 인덱스를 입력하세요.\n인덱스 범위: (1 ~ ' + (words.length) + ')');
        const index = parseInt(input - 1, 10);

        if (!isNaN(index) && index >= 0 && index < words.length) {
            setCurrentIndex(index);
            setDisplayIndex(index);
        } else {
            alert('잘못된 인덱스를 입력하였습니다.');
        }
    }

    const handleSave = () => {
        updateWordsToServer(words);
    };

    const handleBack = () => {
        if (moveRef.current) {
            moveRef.current.click();
        }
    }

    const handleFlashcardVisible = (isVisible) => {
        const root = document.documentElement;

        setIsFlashcardVisible(isVisible);

        if(!isVisible){
            root.style.setProperty('--ddark-words-left-container-display', 'none');
        }else{
            root.style.setProperty('--ddark-words-left-container-display', 'block');
        }
    }

    const handleCopy = () => {
        let wordToCopy;

        if(!isFlipped && !isSentence){
            wordToCopy = incompleteWords[currentIndex].word;
        }else if (isFlipped && !isSentence){
            wordToCopy = incompleteWords[currentIndex].meaning;
        }else if (!isFlipped && isSentence){
            wordToCopy = incompleteWords[currentIndex].example_sentence;
        }else if(isFlipped && isSentence){
            wordToCopy = incompleteWords[currentIndex].example_sentence_meaning;
        }

        navigator.clipboard.writeText(wordToCopy);
    };

    const handleAutoFlip = () => {
        setIsAutoFlip(!isAutoFlip);
    }

    const playAudio = () => {
        let audio;

        if(isDataFetched && isAudioAllowed){
            if (audioEvent.length > 0) {
                audioEvent.forEach(aud => {
                    aud.pause();
                    aud.currentTime = 0;
                    aud.removeEventListener('ended', onAudioPlay);
                });
                setAudioEvent([]);
            }

            let origin = window.location.origin;
            let type;

            if(!isFlipped && !isSentence){
                type = 'jp_word';
            }else if(isFlipped && !isSentence){
                type = 'ko_meaning';
            }else if(!isFlipped && isSentence){
                type = 'jp_example_sentence';
            }else if(isFlipped && isSentence){
                type = 'ko_example_sentence_meaning';
            }

            let audioSrc = `${origin}/api/audio/${type}/${incompleteWords[currentIndex].id}`;

            // 새로운 Audio 객체를 생성하고 src에 지정된 경로를 재생
            audio = new Audio(audioSrc);

            audio.play().catch(error => {
                console.error('Audio playback failed:', error);
            });
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                audio.removeEventListener('ended', onAudioPlay);
            }
        };
    }

    const handlePlayAudio = (e) => {
        e.preventDefault();
        playAudio();
    }

    const handleIsPronunciationHidden = () => {
        setIsPronunciationHidden(!isPronunciationHidden);
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === '2' || e.key === 'w' || e.key === 'W') { // 2, w 눌러 안다 선택
                handleKnow();
            } else if (e.key === '3' || e.key === 'e' || e.key === 'E') { // 3, e 눌러 모른다 선택
                handleDontKnow();
            } else if (e.key === 'q' || e.key === 'Q' || e.key === ' ' || e.key === '1') { // q, 스페이스, 1로 카드 뒤집기
                handleFlip();
            } else if (e.key === 's' || e.key === 'S' ) {
                handleSave(); // s 눌러 저장
            } else if (e.key === 'b' || e.key === 'B') {
                handleBack(); // b 눌러 저장
            } else if (e.key === 'd' || e.key === 'D') {
                handleFlashcardVisible(false) // d 눌러 flashcard 숨기기
            } else if (e.key === 'f' || e.key === 'F') {
                handleFlashcardVisible(true); // f 눌러 flashcard 보이게 하기
            } else if (e.key === 'c' || e.key === 'C') {
                handleCopy(true); // c 눌러 flashcard 텍스트 복사
            } else if (e.key === 'p' || e.key === 'P') {
                handleAutoFlip(); // p 눌러 단어 자동실행/종료
            } else if (e.key === 't' || e.key === 'T') {
                handleSentence(); // t 눌러 단어/문장 전환
            } else if (e.key === 'Tab') {
                handlePlayAudio(e); // Tab 눌러 오디오 실행
            }
        };

        // 키보드 이벤트 리스너 등록
        window.addEventListener('keydown', handleKeyDown);

        // 컴포넌트 언마운트 시 이벤트 리스너 해제
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKnow, handleDontKnow, handleFlip, handleSave, handleBack, handleAutoFlip]);

    const onAudioPlay = () => {
        if(isMobile){
            if(isFlipped){
                handleNextWord();
            }

            clearAudioEvent();
            handleFlip();
        }else{
            let delay = isFlipped ? 500 : 5000;

            setTimeout(() => {
                if(isFlipped){
                    handleNextWord();
                }

                clearAudioEvent();
                handleFlip();
            }, delay);
        }
    }

    const clearAudioEvent = () => {
        audioEvent.forEach(audio => {
            audio.pause();
            audio.currentTime = 0;
            audio.removeEventListener('ended', onAudioPlay);
        });

        setAudioEvent([]);
    }

    const endedSilentAudio = () => {
        silentAudioEvent.forEach(audio => {
            audio.pause();
            audio.currentTime = 0;
            audio.removeEventListener('ended', endedSilentAudio);
        });

        setIsSilentAudioPlaying(false);
        setSilentAudioEvent([]);
    }

    useEffect(() => {
        let tmpFlipTimer;
        let tmpNextWordTimer;

        if (isAutoFlip && !isSentence && !isMobile) {
            if(isFlipped){
                handleFlip();
            }

            if (!flipTimer && !nextWordTimer) { // 타이머 중복 방지
                // tmpFlipTimer = setInterval(() => {
                //     handleFlip();
                // }, 2000);
                //
                // tmpNextWordTimer = setInterval(() => {
                //     handleNextWord();
                // }, 4000);

                setTimeout(() => handleFlip(), 3000);

                tmpFlipTimer = setInterval(() => {
                    handleFlip();
                    setTimeout(() => handleFlip(), 3000); // 앞면 유지 시간
                }, 5000);

                tmpNextWordTimer = setInterval(() => {
                    handleNextWord();
                }, 5000);

                setFlipTimer(tmpFlipTimer);
                setNextWordTimer(tmpNextWordTimer);
            }
        }else if (isAutoFlip && isSentence){
            setTimeout(() => handleFlip(), 3000);
        }else if(isAutoFlip && !isSentence && isMobile){
            setTimeout(() => handleFlip(), 2000);
        }else if (!isAutoFlip && !isSentence) {
            if (tmpFlipTimer) clearInterval(tmpFlipTimer);
            if (tmpNextWordTimer) clearInterval(tmpNextWordTimer);
            setFlipTimer(null);
            setNextWordTimer(null);
        }else if((!isAutoFlip && isSentence) || (!isAutoFlip && isMobile)){
            clearAudioEvent();
        }

        return () => {
            if (tmpFlipTimer) clearInterval(tmpFlipTimer);
            if (tmpNextWordTimer) clearInterval(tmpNextWordTimer);
            setFlipTimer(null);
            setNextWordTimer(null);
        };
    }, [isAutoFlip]);

    useEffect(() => {
        let audio;

        if(isMobile && isAudioAllowed && isAutoFlip && !isSilentAudioPlaying){
            if (silentAudioEvent.length > 0) {
                silentAudioEvent.forEach(aud => {
                    aud.pause();
                    aud.currentTime = 0;
                    aud.removeEventListener('ended', endedSilentAudio);
                });
                setSilentAudioEvent([]);
            }

            let origin = window.location.origin;
            let audioSrc = `${origin}/api/audio/silent/silent_audio`;

            // 새로운 Audio 객체를 생성하고 src에 지정된 경로를 재생
            audio = new Audio(audioSrc);

            audio.addEventListener('ended', endedSilentAudio);
            silentAudioEvent.push(audio);

            audio.play().catch(error => {
                console.error('Audio playback failed:', error);
            });

            setIsSilentAudioPlaying(true);
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                audio.removeEventListener('ended', endedSilentAudio);
            }
        };
    }, [isMobile, isAudioAllowed, isAutoFlip]);

    useEffect(() => {
        let audio;

        if(isDataFetched && isAudioAllowed){
            if (audioEvent.length > 0) {
                audioEvent.forEach(aud => {
                    aud.pause();
                    aud.currentTime = 0;
                    aud.removeEventListener('ended', onAudioPlay);
                });
                setAudioEvent([]);
            }

            let origin = window.location.origin;
            let type;

            if(!isFlipped && !isSentence){
                type = 'jp_word';
            }else if(isFlipped && !isSentence){
                type = 'ko_meaning';
            }else if(!isFlipped && isSentence){
                type = 'jp_example_sentence';
            }else if(isFlipped && isSentence){
                type = 'ko_example_sentence_meaning';
            }

            let audioSrc = `${origin}/api/audio/${type}/${incompleteWords[currentIndex].id}`;

            // 새로운 Audio 객체를 생성하고 src에 지정된 경로를 재생
            audio = new Audio(audioSrc);

            if((isSentence && isAutoFlip) || (isMobile && isAutoFlip)){
                audio.addEventListener('ended', onAudioPlay);
                audioEvent.push(audio);
            }

            audio.play().catch(error => {
                console.error('Audio playback failed:', error);
            });
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                audio.removeEventListener('ended', onAudioPlay);
            }
        };
    }, [isDataFetched, changedWords, displayIndex, isFlipped, isSentence]);

    useEffect(() => {
        let element = document.querySelector('#isSentence');
        if(!element) return;

        if(!isSentence){
            element.textContent = '문장';
        }else{
            element.textContent = '단어';
        }
    }, [isSentence]);

    if(!words || words.length === 0){
        return <div className="loading">Loading...</div>;
    }

    // 변경된 값 저장
    const updateWordsToServer = async updatedWords => {
        if(!isLogin){
            alert('로그인이 필요합니다.');
            return;
        }

        const csrfToken = Cookies.get('XSRF-TOKEN');
        const token = localStorage.getItem('token');

        if (changedWords.length > 0) {
            try {
                const response = await fetch('/api/word/updateMemberWordsState', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(changedWords),  // 변경된 단어들만 서버로 전송
                });

                if (!response.ok) {
                    throw new Error(`서버 에러: ${response.status}`);
                }

                const result = await response.json();
                alert('저장이 완료되었습니다.');
                console.log('저장 성공:', result)
                setChangedWords([]); // 변경 단어 초기화
            } catch (error) {
                alert('저장이 실패하였습니다.');
                console.error('저장 실패:', error);
            }
        } else {
            alert('변경된 데이터가 없습니다.');
            console.log('변경된 데이터가 없습니다.');
        }
    };

    return (
        <div className="words" style={{height: dailyStudy <= 0 ? '100vh' : '91vh'}}>
            {
                dailyStudy > 0 &&
                <div className="words-left-container">
                    <label htmlFor="wordsLeft" className="words-left-label">남은 단어: </label>
                    <span id="wordsLeft">{wordsLeftCnt}</span>
                </div>
            }
            <div className="top-button-container">
                <button onClick={handleSave} className="save-button">저장</button>
                <MovePage ref={moveRef} path={'/'} text={'돌아가기'} classNm={'move-main-button'}/>
                <button id="isSentence" onClick={handleSentence} className="sentence-button">문장</button>
                <button onClick={handlePlayAudio} className="pc-play-audio-button">오디오</button>
                <button onClick={handleIsPronunciationHidden} className="pc-pronunciation-hidden-button">발음표시</button>
                <button onClick={handleAutoPlay} className="autoplay-button">자동재생</button>
            </div>
            <div className="cards">
                <FlashCard ref={cardRef} isVisible={isFlashcardVisible} currentData={incompleteWords[currentIndex]}
                           displayIndex={displayIndex} isFlipped={isFlipped} isSentence={isSentence} isPronunciationHidden={isPronunciationHidden}
                           onClick={handleFlip} indexClickEvent={handleIndexClickEvent}/>
            </div>
            <div className="bottom-button-container">
                <button onClick={handleKnow} className="know-button">안다</button>
                <button onClick={handleDontKnow} className="dont-know-button">모른다</button>
                <button onClick={handlePlayAudio} className="mobile-play-audio-button">오디오</button>
                <button onClick={handleIsPronunciationHidden} className="mobile-pronunciation-hidden-button">발음표시</button>
            </div>
        </div>
    )
}

export default Word;