import React, {forwardRef} from 'react';

const FlashCard = forwardRef(({currentData, displayIndex, isFlipped, isVisible, isSentence, isPronunciationHidden, onClick, indexClickEvent}, ref) => {
    let content;
    let word;
    let pronunciation;
    let back;

    if(currentData){
        if(isSentence){
            word = currentData.example_sentence;
            pronunciation = currentData.example_sentence_pronunciation;
            back = currentData.example_sentence_meaning;
        }else if(!isSentence && isPronunciationHidden){
            word = currentData.word;
            pronunciation = currentData.pronunciation;
            back = currentData.meaning;
        }else if(!isSentence && !isPronunciationHidden){
            word = currentData.word;
            pronunciation = currentData.pronunciation;
            back = currentData.meaning;
        }
    }else{
        word = '';
        pronunciation = '';
        back = '';
    }

    if (isFlipped) {
        if(isPronunciationHidden){
            content = <div className="back">{back}</div>
        }else{
            if (localStorage.getItem('designMode') === 'light') {
                content = <div className="back">{back}<br/>{'（' + pronunciation + '）'}</div>
            } else if(localStorage.getItem('designMode') === 'ddark' && !isSentence) {
                content = <div className="back">{back + '（' + pronunciation + '）'}</div>
            } else if(localStorage.getItem('designMode') === 'ddark' && isSentence) {
                content = <div className="back">{back}<br/>{pronunciation}</div>
            }
        }
    } else if (!isFlipped && !isSentence && isPronunciationHidden){
        if (localStorage.getItem('designMode') === 'light') {
            content = <div className="front">{word}<br/>{'（' + pronunciation + '）'}</div>
        } else if (localStorage.getItem('designMode') === 'ddark') {
            content = <div className="front">{word + '（' + pronunciation + '）'}</div>
        }
    } else if (!isFlipped && !isSentence && !isPronunciationHidden){
        if (localStorage.getItem('designMode') === 'light') {
            content = <div className="front">{word}</div>
        } else if (localStorage.getItem('designMode') === 'ddark') {
            content = <div className="front">{word}</div>
        }
    } else if (!isFlipped && isSentence && isPronunciationHidden){
        if (localStorage.getItem('designMode') === 'light') {
            content = <div className="front">{word}<br/>{'（' + pronunciation + '）'}</div>
        } else if (localStorage.getItem('designMode') === 'ddark') {
            content = <div className="front">{word}<br/>{'（' + pronunciation + '）'}</div>
        }
    } else if (!isFlipped && isSentence && !isPronunciationHidden){
        if (localStorage.getItem('designMode') === 'light') {
            content = <div className="front">{word}</div>
        } else if (localStorage.getItem('designMode') === 'ddark') {
            content = <div className="front">{word}</div>
        }
    }

    return (
        <div ref={ref} className="flashcard" onClick={onClick} style={{display: isVisible ? '' : 'none'}}>
            <div className="index" onClick={indexClickEvent}>{displayIndex + 1}</div>
            {content};
        </div>
    );
});

export default FlashCard;