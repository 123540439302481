import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setCurrentLevel} from "../redux/actions";

const LevelSelector = () => {
    const dispatch = useDispatch();

    const [level, setLevel] = useState(() => {
        return localStorage.getItem('currentLevel') || 'N3';
    });

    useEffect(() => {
        dispatch(setCurrentLevel(level));
    }, []);

    const onChange = (e) => {
        const newLevel = e.target.value;
        setLevel(newLevel);
        localStorage.setItem('currentLevel', newLevel);

        dispatch(setCurrentLevel(newLevel));
    }

    return (
        <div className="select-container">
            <label htmlFor="currentLevel" className='page-label'>단계:</label>
            <select id="currentLevel" className='page-select-box' value={level} onChange={onChange}>
                <option value={'N2'}>N2</option>
                <option value={'N3'}>N3</option>
                <option value={'N4'}>N4</option>
                <option value={'N5'}>N5</option>
                <option value="all">전체</option>
            </select>
        </div>
    );
}

export default LevelSelector;