import React, {useState} from 'react';

const PageSizeSelector = () => {
    const [value, setValue] = useState(() => {
        return localStorage.getItem('pageSize') || 300;
    })

    const onChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        localStorage.setItem('pageSize', newValue);
    }

    return (
        <div className="select-container">
            <label htmlFor="pageSize" className='page-label'>페이지:</label>
            <select id="pageSize" className='page-select-box' value={value} onChange={onChange}>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={300}>300</option>
                <option value={500}>500</option>
                <option value="100000">전체</option>
            </select>
        </div>
    );
}

export default PageSizeSelector;