import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setIsRandom} from "../redux/actions";

const RandomSelector = () => {
    const dispatch = useDispatch();

    const [randomValue, setRandomValue] = useState(() => {
        return localStorage.getItem('isRandom') === 'true' || true;
    });

    useEffect(() => {
        dispatch(setIsRandom(randomValue));
    }, []);

    const onChange = (e) => {
        const newValue = !randomValue;
        setRandomValue(newValue);
        localStorage.setItem('isRandom', newValue);

        dispatch(setIsRandom(newValue));
    }

    return (
        <div className="checkbox-container">
            <input type="checkbox" id="isRandom" className="isRandom" checked={randomValue} onChange={onChange}/>
            <label htmlFor="isRandom" className='page-label'>랜덤</label>
        </div>
    );
}

export default RandomSelector;