const initialState = {
    isAudioAllowed: false,
    currentLevel: 'N3'
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_AUDIO_ALLOWED':
            return {
                ...state,
                isAudioAllowed: action.payload
            };
        case 'SET_CURRENT_LEVEL':
            return {
                ...state,
                currentLevel: action.payload
            };
        case 'SET_IS_RANDOM':
            return {
                ...state,
                isRandom: action.payload
            };
        default:
            return state;
    }
};

export default reducer;