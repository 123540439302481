import React, {useEffect, useState} from 'react';

const DailyStudy = () => {
    const [value, setValue] = useState(() => {
        return localStorage.getItem('dailyStudy') || '0';
    });

    const handleClick = () => {
        const userInput = prompt('일일 공부량을 입력하세요\n(0 입력시 공부량 설정하지 않음)');

        if (!isNaN(userInput) && userInput >= 0 && userInput <= 100000) {
            setValue(Number(userInput));
        }else{
            alert('잘못된 값을 입력하였습니다.');
        }
    };

    useEffect(() => {
        localStorage.setItem('dailyStudy', value);
    }, [value]);

    return (
        <div className='daily-study-container'>
            <label htmlFor='daily-study-label' className='daily-study-label'>오늘의 공부량:</label>
            <span id='dailyStudy' onClick={handleClick}>{value}</span>
        </div>
    );
}

export default DailyStudy;