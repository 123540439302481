export const setAudioAllowed = (isAllowed) => {
    return {
        type: 'SET_AUDIO_ALLOWED',
        payload: isAllowed
    };
};

export const setCurrentLevel = (level) => ({
    type:'SET_CURRENT_LEVEL',
    payload: level
});

export const setIsRandom = (isRandom) => ({
    type: 'SET_IS_RANDOM',
    payload: isRandom
});