import React from 'react';
import MovePage from './MovePage'
import PageSizeSelector from "./PageSizeSelector";
import LevelSelector from "./LevelSelector";
import DailyStudy from "./DailyStudy";
import RandomSelector from "./RandomSelector";

const Main = () => {
    return (
        <div className="main">
            <div className="contents-box">
                <div className='main-selector-container'>
                    <PageSizeSelector/>
                    <LevelSelector/>
                    <DailyStudy/>
                    <RandomSelector/>
                </div>
                <MovePage path={'/word'} text={'단어'} classNm={'menu-button'}/>
                {/*<MovePage path={'/kanji'} text={'문장'} classNm={'menu-button'}/>*/}
                <MovePage path={'/kanji'} text={'한자'} classNm={'menu-button'}/>
            </div>
        </div>
    )
}

export default Main;