import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';

const Login = () => {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');
    const [userIdError, setUserIdError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const loginRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (loginRef.current && !loginRef.current.contains(event.target)) {
                navigate('/'); // 메인 페이지로 이동
            }
        };
        // 클릭 이벤트를 window에 등록
        window.addEventListener('mousedown', handleClickOutside);

        return () => {
            // 컴포넌트 언마운트 시 이벤트 리스너 제거
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navigate]);

    const handleUserIdChange = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z0-9]*$/; // 영문자와 숫자만 허용
        if (value.length > 20) {
            setUserIdError('id는 최대 20자 이내로 입력해주세요.');
        } else if (!regex.test(value)) {
            setUserIdError('id는 영문자와 숫자만 사용할 수 있습니다.');
        } else {
            setUserIdError('');
        }
        setUserId(value);
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]*$/;

        if(value.length > 30){
            setPasswordError('비밀번호는 최대 30자 이내로 입력해주세요.');
        }else if(!regex.test(value)){
            setPasswordError('비밀번호는 영문자, 숫자, 특수문자만 사용할 수 있습니다.');
        }else{
            setPasswordError('');
        }
        setPassword(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // 모든 에러가 없는 경우에만 폼 제출
        if (!userIdError && !passwordError && userId && password) {
            // 폼 제출 로직
            const csrfToken = Cookies.get('XSRF-TOKEN');

            const param = {
                userId: userId,
                password: password,
            };

            try {
                const response = await fetch('/api/member/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken  // CSRF 토큰을 헤더에 포함
                    },
                    credentials: 'include',
                    body: JSON.stringify(param)
                });

                const result = await response.json();
                localStorage.setItem('token', result.token);

                if (response.ok) {
                    alert(result.message);
                    navigate('/');
                } else {
                    alert(result.message);
                }
            } catch (error) {
                alert(error);
                console.error('폼 제출 중 오류 발생', error);
            }
        } else {
            let errorMessage = '';

            if (userIdError) {
                errorMessage = userIdError;
            } else if (passwordError) {
                errorMessage = passwordError;
            } else {
                errorMessage = '폼을 올바르게 작성해주세요.';
            }

            // 에러가 있는 경우 제출 막기
            setError(errorMessage);
            alert(errorMessage);
        }
    };

    return (
        <div className="signup-container">
            <div className="signup-box">
                <h2>로그인</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="userId">id</label>
                        <input
                            type="text"
                            id="userId"
                            value={userId}
                            onChange={handleUserIdChange}
                            required
                            onInvalid={(e) => e.target.setCustomValidity('id를 입력하세요.')}
                            onInput={(e) => e.target.setCustomValidity('')}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">비밀번호</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            onInvalid={(e) => e.target.setCustomValidity('비밀번호를 입력하세요.')}
                            onInput={(e) => e.target.setCustomValidity('')}
                        />
                    </div>
                    <button type="submit" className="signup-button">로그인</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
