import React, {useEffect, useState} from 'react';
import alarmImage from '../img/alarm_icon.png';
import {useDispatch} from "react-redux";
import {setAudioAllowed} from "../redux/actions";

const AlarmIcon = () => {
    const dispatch = useDispatch();
    const [isAudioAllowed, setIsAudioAllowed] = useState(() => {
        return localStorage.getItem('audioAllowed') === 'true';
    });

    useEffect(() => {
        dispatch(setAudioAllowed(isAudioAllowed));
    }, []);

    const clickEvent = () => {
        const newAudioAllowedStatus = !isAudioAllowed;
        setIsAudioAllowed(newAudioAllowedStatus);
        localStorage.setItem('audioAllowed', newAudioAllowedStatus.toString());

        // Redux 상태 업데이트
        dispatch(setAudioAllowed(newAudioAllowedStatus));
    };

    return (
        <img
            className='alarm'
            src={alarmImage}
            alt="Alarm Icon"
            width="30"
            height="30"
            onClick={clickEvent}
            style={{ filter: isAudioAllowed ? '' : 'grayscale(100%)' }}
        />
    );
}

export default AlarmIcon;